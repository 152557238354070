@import 'settings/variable'; //themes' colors

//base...
@import 'generic/normalize.scss';
@import 'generic/box-sizing.scss';
@import './base/scaffolding'; // styles of base elements
@import './base/typography'; // base styles of h1-h6, p, span

//components...
@import 'component/alert';
@import 'component/back';
@import 'component/btn';
@import './component/card';
@import './component/check-box';
@import './component/sidebar';
@import './component/topbar';
@import './component/leaflet';
@import './component/load';
@import './component/form';
@import './component/panel';
@import './component/timeline';
@import './component/responsive-tabs.scss';
@import './component/divider-information.scss';

//pages...
@import 'containers/account';
@import 'containers/not-found.scss';
@import 'containers/orderdetails.scss';

//objects...
@import 'objects/layout.scss';

//@import '~antd/dist/antd.css';
@import 'antd/custom-theme.css';

// overruling
.ant-descriptions-small .ant-descriptions-row > th {
  padding-bottom: 0px;
}

.MuiTableCell-head {
  color: rgba(0, 0, 0, 0.85) !important;
  font-weight: normal !important;
}

.MuiTableCell-body {
  color: rgba(0, 0, 0, 0.65) !important;
}

.mui-datatable-list {
  margin-top: -30px !important;

  @media only screen and (max-width: 600px) {
    margin-top: 0px !important;
  }

  @media print and (max-width: 600px) {
    margin-top: 0px !important;
  }
}


.MuiTypography-root {
  font-size: 14px !important;
}

.MuiTable-root tr {
  @media only screen and (max-width: 600px) {
    border-bottom: 2px solid black;
  }
}


.mui-datatable-list.mui-datatable-with-panel-title {
  margin-top: -75px !important;
}


.mui-datatable-list .MuiToolbar-regular {
  padding-left: 0;
  padding-right: 0;
}


/*
.MuiToolbar-regular {
  @media only screen and (max-width: 600px) {
    display: none !important;
  }
}
*/

.mui-datatable-list .MuiToolbar-regular > div {
  background: white;
}

.mui-datatable-list .MuiTableRow-root.MuiTableRow-hover:hover {
  cursor: pointer;
}

.anticon svg {
  vertical-align: baseline;
}
