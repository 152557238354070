.responsive-tabs {
  width: 100%;

  margin-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;

  .ant-select-selector {
    border: 2px solid #8dac7f !important;
    font-weight: bold;
    background-color: #c6cec3 !important;
  }

  .ant-menu {
    background-color: transparent;
  }
}

.responsive-tabs__wrapper.tabs__wrapper--desktop {
  display: none;

  @media screen and (min-width: 576px) {
    display: block;
  }
}

.responsive-tabs__wrapper.tabs__wrapper--mobile {
  display: none;

  @media screen and (max-width: 576px) {
    display: block;
  }
}
