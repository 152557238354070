.leaflet-popup-content {
  margin: 5px 5px !important;
  line-height: 1 !important;
  margin-right: 20px !important;
  font-size: 11px;

  .title {
    font-weight: bold;
  }

  .link {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    margin: 7px 0 7px 0;
  }

  hr {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
}
