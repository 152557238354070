.jbi-back-wrapper {
  margin: 20px;
}

.jbi-back {
  cursor: pointer;
  display: inline;

  padding: 10px;

  &:hover {
    @include themify($themes) {
      background-color: themed('colorHover');
    }
  }
}
